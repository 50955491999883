import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import LabsPage from "../templates/labs";

export const query = graphql`
  query LabsPageQuery {
    sanityLabsSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      labs {
        id
        title
        subtitle
        _rawBody
        links {
          target
          label
        }
        attachments {
          label
          attachment {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

const LabPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const sanityLabsSettings = (data || {}).sanityLabsSettings;

  return <LabsPage sanityLabsSettings={sanityLabsSettings} />;
};

export default LabPage;
